<template lang="html">
  <div class="screen">
    <h1>Good Jobs Bro</h1>
    <h3>Finish in {{ Math.round(totalTime / 920) }}'s</h3>
    <button @click="onStartAgain">Play Again</button>
  </div>
</template>
<script>
export default {
  props: {
    totalTime: {
      type: Number,
      default: 0,
    },
  },
  emits: ["startAgain"],
  methods: {
    onStartAgain() {
      this.$emit("startAgain");
    },
  },
};
</script>
<style lang="css" scoped>
.screen {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--dark);
  color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.screen h1 {
  font-size: 5rem;
}
.screen h3 {
  margin-top: 1.5rem;
  font-size: 3rem;
}
.screen button {
  font: var(--font);
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--light);
  color: var(--light);
  margin: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}
.screen button:hover {
  background-color: var(--light);
  color: var(--dark);
}
</style>
